import React from "react"
import imgPortadaGuide from "@components/pagePlantillas/plantillas/plantilla4/image/imgPortada.png"
import img1 from "@components/pagePlantillas/plantillas/plantilla4/image/img.png"
import silder1 from "@components/pagePlantillas/plantillas/plantilla4/image/silder1.png"
import silder2 from "@components/pagePlantillas/plantillas/plantilla4/image/silder2.png"
import silder3 from "@components/pagePlantillas/plantillas/plantilla4/image/silder3.png"

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
  </span>
)


const data = {
  start: {
    support: "Plantilla | Contact Center",
    title: <h1 className="titles-underlined-ebook ebook-ventas">
     10 encuestas de satisfacción al cliente en un Contact Center
    </h1>,
    description: <p className="text-h2-ebook guide__description">
      Las encuestas de satisfacción ayudan a medir la experiencia del cliente en cada interacción. Define los objetivos de cada encuesta para obtener información clara y útil. Segmenta a los clientes según los servicios utilizados o el tipo de interacción. Asegúrate de que las preguntas sean específicas y fáciles de responder.
    </p>,
    classtext: "guide__description1",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía-para-una-buena-experiencia-de-compra",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Experiencia General del Cliente.",
      },
      {
        key: 2,
        text: "Calidad de la Interacción.",
      },
      {
        key: 3,
        text: " Resolución de Problemas.",
      },
      {
        key: 4,
        text: "Automatización y Autoservicio.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: silder1,
      },
      {
        key: 2,
        img: silder2,
      },
      {
        key: 3,
        img: silder3,
      },
    ],
  },

  description: {
    cards: [
      {
        text: <p>
          Recuerda que, con esta guía, podrás aprender cómo estructurar encuestas enfocadas en evaluar la atención, tiempos de respuesta y resolución de problemas. Además, incluye ejemplos prácticos para implementarlas y herramientas para automatizar el proceso.
          <br /><br />
          Por último, descarga la guía y conoce más sobre cómo mejorar la experiencia de tus clientes con encuestas bien diseñadas.
        </p>,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
