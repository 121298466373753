import React from "react"
import TemplatePage from "@components/pagePlantillas/template"
import { Helmet } from "react-helmet"
import data from "@components/pagePlantillas/plantillas/plantilla4/data"

const IndexPage = ({ location }) => (
  <div className="fnd">
    <Helmet htmlAttributes={{ lang: "es" }}>
      <title>
        [Plantilla Gratis] 10 encuestas de satisfacción al cliente en un Contact Center [Antes Securitec]
      </title>
      <link rel="icon" type="image/png" href={`https://beexcc.com/Beex.png`} />
      <link
        rel="canonical"
        href={`https://beexcc.com/recursos/plantillas/encuesta-satisfaccion-contact-center/`}
      />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Conoce qué revelan las encuestas de satisfacción al cliente en un Contact Center. Conoce la experiencia de los usuarios y sus opiniones."
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="[Plantilla Gratis] 10 encuestas de satisfacción al cliente en un Contact Center"
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/recursos/plantillas/encuesta-satisfaccion-contact-center/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/metaimagenRecursos/Plantillas/og-plantillas4.jpg`}
      />
      <meta
        property="og:description"
        content="Conoce qué revelan las encuestas de satisfacción al cliente en un Contact Center. Conoce la experiencia de los usuarios y sus opiniones."
      />
    </Helmet>
    <TemplatePage
      ebook="Contact Center"
      location={location}
      typePlantilla="htemplate04"
      clickHereHref="https://beexcc.com/blog/"
      data={data}
    />
  </div>
)

export default IndexPage
